import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "~/components/seo"
import PageHero from "~/components/page-hero"
import ShopLayout from "~/components/shop-layout"

const ShopTile = ({ tile }) => {
  const { id, image, link } = tile
  return (
    <Link key={id} to={link.to} className="relative hover:text-orange">
      <div className="relative overflow-hidden rounded">
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={link.text}
          className="h-96 w-auto rounded transform transition ease-in-out duration-200 hover:scale-110 "
        />
      </div>

      <div className="text-lg text-center mt-4 pointer-events-none">
        <p>{link.text}</p>
      </div>
    </Link>
  )
}

const Shop = ({ data: { page } }) => {
  return (
    <>
      <Seo title="Shop" />
      <PageHero title={page.hero.title} image={page.hero.coverImage.gatsbyImageData} />

      <ShopLayout>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-6 xl:gap-x-10 gap-y-8 sm:mx-6 lg:mx-0 md:max-w-prose xl:max-w-none">
          {page.shopTiles.map(tile => (
            <ShopTile key={tile.id} tile={tile} />
          ))}
        </div>
      </ShopLayout>
    </>
  )
}

export const query = graphql`
{
  page: contentfulPageShop {
    hero {
      title
      coverImage {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    shopTiles {
      id
      image {
        gatsbyImageData(quality: 70, layout: FULL_WIDTH)
      }
      link {
        text
        to
      }
    }
  }
}
`

export default Shop
